
// https://blog.logrocket.com/how-to-implement-form-validation-with-vuetify-in-a-vue-js-app/
import TextField from '@/components/TextField';
import { ValidationObserver, extend } from 'vee-validate';
import { messages } from 'vee-validate/dist/locale/en.json';
import { required, email, max } from 'vee-validate/dist/rules';
import { mdiClose } from '@mdi/js';
const rules = { required, email, max };
for (const [rule, validation] of Object.entries(rules)) {
  extend(rule, {
    ...validation,
    message: messages[rule],
  });
}
extend('password', {
  validate: (v) => {
    return /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(v);
  },
  message:
    'Password must contain at least lowercase letter, one number, a special character and one uppercase letter.',
});
export default {
  components: {
    ValidationObserver,
    TextField,
  },
  middleware: ['auth'],
  auth: 'guest',
  data: () => ({
    mdiClose,
    form: { password: '', email: '', rememberMe: false },
    valid: false,
    submitting: false,
    errorMessage: null,
    snackbar: true,
  }),
  head() {
    return {
      title: 'Login',
    };
  },
  methods: {
    async submit() {
      this.submitting = true;
      try {
        await this.$auth.loginWith('laravelSanctum', {
          data: this.form,
        });
      } catch (error) {
        if (error.response && error.response.status !== 422) {
          this.$nuxt.error({
            statusCode: error.response.status,
            message: error.message,
          });
        } else if (error.response && error.response.status === 422) {
          this.errorMessage = error.response.data.message;
        } else {
          this.errorMessage = error.message ?? error;
        }
      }
      this.submitting = false;
    },
  },
};
