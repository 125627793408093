
import { ValidationProvider } from 'vee-validate';
export default {
  name: 'BaseTextField',
  components: { ValidationProvider },
  props: {
    rules: {
      type: [Object, String],
      default: '',
    },
  },
};
